import MapboxGl from "mapbox-gl"

// Import Mapbox CSS to force it to be processed by Webpack
require("mapbox-gl/dist/mapbox-gl.css")

// Import root CSS to make all CSS bundled
require("./app.css")

const countryLabelsGeoJson = require("./countries.json");

// Pulled from https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson
const usStateBoundaries = require("./us-states.json");

// Set access token so Mapbox GL can access tile data
MapboxGl.accessToken = (
  "pk.eyJ1IjoiYW50ZW5uZW4iLCJhIjoiY2l6NDZkdnp3MDJ2MjMybW5xemQ0bGhvZSJ9.1xrH4" +
  "0vEh7HZiy0PhLnCOA"
);

function toRadians (angle) {
    return angle * (Math.PI / 180);
}

function haversineDistance(a, b) {
    var R = 6371000; // Average earth radius

    var latA = toRadians(a[1]);
    var latB = toRadians(b[1]);
    var latDelta = latB - latA;
    var lonDelta = toRadians(b[0] - a[0]);

    var p = Math.sin(latDelta / 2) * Math.sin(latDelta / 2)
        + Math.cos(latA) * Math.cos(latB)
        * Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2);
    var q = 2 * Math.atan2(Math.sqrt(p), Math.sqrt(1 - p));
    var d = R * q;

    return d;
}

function weightedMean(a, b, aw, bw) {
    aw = typeof aw === "undefined" ? 1 : aw;
    bw = typeof bw === "undefined" ? 1 : bw;
    return (a * aw + b * bw) / (aw + bw);
}

function parseGpx(gpx, timeout, minDistance) {
    if (!timeout) {
        timeout = 600;
    }

    if (!minDistance) {
        minDistance = 40;
    }

    var segments = [];
    var coords = [];
    var lastTrkpt = null;
    var boundingBox = null;
    var clusterCount = 0;
    var clusters = [];

    gpx.querySelectorAll("trkpt").forEach(function(el, i) {
        // Use coordinate along with time to draw dashed lines
        // between segments when a timeout between measurements is
        // exceeded
        var currTrkpt = {
            "coord": [el.attributes.lon.value, el.attributes.lat.value],
            "time": new Date(el.querySelector("time").textContent),
        };

        if (lastTrkpt !== null) {
            // Calculate bounding box for the entire GPX file
            if (boundingBox === null) {
                boundingBox = MapboxGl.LngLatBounds.convert([
                    lastTrkpt.coord,
                    currTrkpt.coord,
                ]);
            } else {
                boundingBox.extend(currTrkpt.coord);
            }

            // Group continuos points that are very close
            const distance = haversineDistance(
                lastTrkpt.coord,
                currTrkpt.coord,
            );
            if (distance < minDistance) {
                clusterCount++;
                currTrkpt.coord[0] = weightedMean(
                    lastTrkpt.coord[0],
                    currTrkpt.coord[0],
                    clusterCount,
                );
                currTrkpt.coord[1] = weightedMean(
                    lastTrkpt.coord[1],
                    currTrkpt.coord[1],
                    clusterCount,
                );

                // Remove last point since we replace it with the weighted
                // average
                coords.pop();
            } else {
                // If the grouping is done, consider adding a dot for it
                if (clusterCount >= 2) {
                    clusters.push({
                        "coord": lastTrkpt.coord,
                        "count": clusterCount
                    })
                }

                clusterCount = 0;
            }
        }

        // Split GPX file into segments if there is more than timeout seconds
        // between to track points
        const timeoutMs = timeout * 1000;
        if (lastTrkpt !== null
            && coords.length
            && currTrkpt.time.valueOf() - lastTrkpt.time.valueOf() > timeoutMs
        ) {
            segments.push(coords);
            coords = [currTrkpt.coord];
        } else {
            coords.push(currTrkpt.coord);
        }

        lastTrkpt = currTrkpt;
    });
    segments.push(coords);

    return {
        "segments": segments,
        "boundingBox": boundingBox,
        "clusters": clusters,
    };
}

function segmentsToGeojson(segments) {
    var lastSegment = segments[segments.length - 1];

    // Add GeoJSON features one by one
    var features = [];

    // Start point
    features.push({
        "id": "poi",
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": segments[0][0],
        },
        "properties": {
            "icon": "circle",
            "title": "Start",
        },
    });

    // End point
    features.push({
        "id": "poi",
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": lastSegment[lastSegment.length - 1],
        },
        "properties": {
            "icon": "triangle",
            "title": "Slut",
        },
    });

    // Tracking segments
    features.push({
        "type": "Feature",
        "geometry": {
            "type": "MultiLineString",
            "coordinates": segments,
        },
        "properties": {
            "style": "solid",
        },
    });

    // Dashed lines between segments
    if (segments.length > 1) {
        // Calculate paths between segments
        var transitions = [];
        for (let i = 1; i < segments.length; i++) {
            transitions.push([
                segments[i - 1][segments[i - 1].length - 1],
                segments[i][0],
            ]);
        }

        features.push({
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": transitions,
            },
            "properties": {
                "style": "dashed",
            },
        })
    }

    return {
        "type": "FeatureCollection",
        "features": features,
    };
}

class GpxMapEl extends HTMLElement {
    constructor() {
        super();
        this.mapbox = null;
    }
    connectedCallback() {
        const gpxPath = this.getAttribute("src");

        const map = new MapboxGl.Map({
          attributionControl: false,
          container: this,
          style: "mapbox://styles/mapbox/streets-v12",
        });
        map.addControl(
          new MapboxGl.ScaleControl({
            maxWidth: 120,
            unit: "metric",
          }),
          "bottom-right",
        );
        map.addControl(
          new MapboxGl.AttributionControl({
              compact: true,
          }),
          "top-right"
        );
        map.on("load", async function fetchGpx() {
            const rsp = await fetch(gpxPath);
            const rawXml = await rsp.text();

            const parser = new DOMParser();
            const gpx = parseGpx(parser.parseFromString(rawXml, "text/xml"));

            // Zoom map to fit all segments
            map.fitBounds(gpx.boundingBox, {
                "padding": 40,
                "linear": true,
            });

            const geoJson = segmentsToGeojson(gpx.segments);
            for (let cluster of gpx.clusters) {
                geoJson.features.push({
                    "id": "cluster",
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": cluster.coord,
                    },
                    "properties": {
                        "count": cluster.count,
                        "size": Math.min(30, 2 + 2 * cluster.count),
                    },
                });
            }

            map.addSource("gpx", {
                "type": "geojson",
                "data": geoJson,
            });

            // Layer with continuous segments
            map.addLayer({
                "id": "gpx-segments",
                "type": "line",
                "source": "gpx",
                "filter": ["==", "style", "solid"],
                "layout": {
                    "line-join": "round",
                    "line-cap": "round"
                },
                "paint": {
                    "line-color": "#5d60be",
                    "line-width": 3
                },
            });

            // Layer with dashed lines for transitions between segments
            map.addLayer({
                "id": "gpx-segments-transitions",
                "type": "line",
                "source": "gpx",
                "filter": ["==", "style", "dashed"],
                "paint": {
                    "line-color": "#5d60be",
                    "line-dasharray": [1, 0.5],
                    "line-width": 3
                },
            });

            // Layer for icons
            map.addLayer({
                "id": "gpx-clusters",
                "type": "circle",
                "source": "gpx",
                "paint": {
                    "circle-radius": {"type": "identity", "property": "size"},
                    "circle-color": "#5d60be",
                    "circle-opacity": 0.4
                },
                "filter": ["has", "count"],
            });

            // Layer for icons
            map.addLayer({
                "id": "gpx-icon",
                "type": "symbol",
                "source": "gpx",
                "layout": {
                    "icon-image": "{icon}_15",
                    "text-field": "{title}",
                    "text-font": ["Roboto Regular"],
                    "text-offset": [0, 0.6],
                    "text-anchor": "top"
                },
                "filter": ["has", "icon"],
            });
        })

        this.mapbox = map;
    }
    disconnectedCallback() {
        if (this.mapbox != null) {
          this.mapbox.remove();
          this.mapbox = null;
        }
    }
}

class CountryMapEl extends HTMLElement {
    constructor() {
        super();
        this.mapbox = null;
    }
    connectedCallback() {
        const countries = this.getAttribute("countries").split(",");
        const usStates = this.getAttribute("us-states").split(",");

        const map = new MapboxGl.Map({
          attributionControl: false,
          container: this,
          style: "mapbox://styles/mapbox/light-v11",
          center: [30.317168, 19.570194],  // Sensible default for showing most countries in Europe
          zoom: 1,
          minZoom: 1,
          maxZoom: 3,
          interactive: false,
        });

        // Views are defined as pairs of longitude and latitudes respectively. The first pair is SE
        // and the second pair is north east
        const views = [
          // Europe
          [
            // SW
            [-8.07233255851159, 35.42269481301537],
            // NE
            [46.12952251899776, 68.951550533138],
          ],
          // Asia (China)
          [
            [76.11028199072251, 24.068367484257294],
            [151.5822420472282, 46.59578429179183],
          ],
          // South East Asia
          [
            [91.6823496887742, -13.257718901358174],
            [143.54081111770677, 26.1683586729271],
          ],
          // North America
          [
            [-122.30556266472422, 25.382367064307957],
            [-44.821350240811626, 54.43492111863861],
          ],
        ];

        function flyToNextView(nextView) {
          map.fitBounds(
            views[nextView % views.length],
            {
              duration: 2500,
              // How much to zoom out between jumps
              curve: 1.8,
            },
          );
          setTimeout(() => flyToNextView(nextView + 1), 7500);
        }

        map.on("style.load", function() {
          // Remove background
          map.setFog(null);

          // Remove text layers we are not interested in
          for (const layer of map.getStyle().layers) {
            if (layer.type == "symbol") {
              map.removeLayer(layer.id);
            }
          }
        });

        map.on("load", function() {
          map.addLayer({
            id: "country-boundaries",
            source: {
              type: "vector",
              url: "mapbox://mapbox.country-boundaries-v1",
            },
            "source-layer": "country_boundaries",
            type: "fill",
            paint: {
              "fill-color": "#000",
              "fill-opacity": 0.3,
            },
            filter: [
              "all",
              ["in", ["get", "iso_3166_1"], ["literal", countries.filter((c) => c != "US")]],
              // Prevent country stacking by sticking to the general or US world view
              [
                "any",
                ["==", "all", ["get", "worldview"]],
                ["in", "US", ["get", "worldview"]],
              ],
            ],
          });

          map.addSource("us-states", { "type": "geojson", "data": usStateBoundaries });
          map.addLayer({
            id: "us-state-boundaries",
            source: "us-states",
            type: "fill",
            paint: {
              "fill-color": "#000",
              "fill-opacity": 0.3,
            },
            filter: ["in", ["get", "iso_3166_1"], ["literal", usStates]],
          });

          map.addLayer({
            id: "us-state-labels",
            source: {
              type: "vector",
              url: "mapbox://mapbox.mapbox-streets-v8",
            },
            "source-layer": "place_label",
            type: "symbol",
            paint: {
              // Copied from light-v11
              "text-color": "hsl(220, 1%, 49%)",
              "text-halo-color": "hsl(220, 0%, 100%)",
              "text-halo-width": 1.25,
            },
            layout: {
              "text-allow-overlap": true,
              "text-field": ["get", "name"],
              "text-size": 9,

              // Copied from light-v11
              "text-font": ["DIN Pro Medium"],
              "text-line-height": 1.1,
              "text-max-width": 6,
            },
            filter: [
              "all",
              ["==", ["get", "class"], ["literal", "state"]],
              ["in", ["get", "iso_3166_2"], ["literal", usStates.map((c) => `US-${c}`)]],
            ],
          });

          map.addSource("countries", { "type": "geojson", "data": countryLabelsGeoJson });
          map.addLayer({
            id: "country-labels",
            source: "countries",
            type: "symbol",
            paint: {
              // Copied from light-v11
              "text-color": "hsl(220, 1%, 49%)",
              "text-halo-color": "hsl(220, 0%, 100%)",
              "text-halo-width": 1.25,
            },
            layout: {
              "text-allow-overlap": true,
              "text-field": ["get", "name"],
              "text-size": 12,

              // Copied from light-v11
              "text-font": ["DIN Pro Medium"],
              "text-line-height": 1.1,
              "text-max-width": 6,
            },
            // Only include visited countries
            filter: ["in", ["get", "iso_3166_1"], ["literal", countries]],
          });

          // Center camera on the first view
          flyToNextView(0);
        });

        this.mapbox = map;
    }
    disconnectedCallback() {
        if (this.mapbox != null) {
          this.mapbox.remove();
          this.mapbox = null;
        }
    }
}

customElements.define("gpx-map", GpxMapEl);
customElements.define("country-map", CountryMapEl);
